.footer{
position:relative !important;
clear:both;
}
#content{
    height:auto !important;
    margin-bottom:20px !important;
}
#support{
    margin-left:auto;
    margin-right:auto;
    float:none !important;
}
#feedback_type{
    width:30% !important;
}
#feedback_name, #feedback_email, #feedback_institute, #feedback_location {
    width:100%;
}
